<template>
  <div>
    <!-- main page -->
      <div v-if="!isImport">
        <vs-button class="ml-auto mb-5" v-on:click="isImport = true" color="success"
          >import</vs-button
        >
        <form-input :progress="'released'" :progressRestriction="true" :selectedBudget="selectedBudget"></form-input>
      </div>
      <div v-else>
        <datatable-import
          :progress="'released'"
          @close="isImport = false"
          :uploadTemplate="uploadTemplate"
          :progressRestriction="true"
        ></datatable-import>
      </div>
  </div>
</template>

<script>
import DatatableImport from "./datatable-import";
import FormInput from "./form";

export default {
  components: {
    DatatableImport,
    FormInput,
  },
  props: ["territory", "salesChannel", "customerCategory", "sourceTab","selectedBudget"],
  data() {
    console.log("this.sourceTab2", this.sourceTab);
    var sub_tab = 0;
    if (this.sourceTab) {
      sub_tab = this.sourceTab;
    }
    let uploadTemplate = false
    if (this.$route.query.upload_template) {
      uploadTemplate = true
    }
    return {
      sub_tab: sub_tab,
      isImport: false,
      uploadTemplate :uploadTemplate,
    };
  },
  watch: {
    //
  },
  mounted() {},
  computed: {},
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    changeSubTab() {},

    clickEdit(budget, sourceTab) {
      this.$emit("selectBudget", budget);
      this.$emit("redirectTab", 0, false, sourceTab);
    },
    clickView(budget, sourceTab) {
      this.$emit("selectBudget", budget);
      this.$emit("redirectTab", 0, true, sourceTab);
    },
  },
};
</script>

<style>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
