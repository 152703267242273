<template>
  <div>
    <!-- start row -->
    <div class="vx-row w-full">
      <!-- start left -->
      <div class="vx-col w-3/12">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <b>Sheet Name</b>
          </div>
          <div class="vx-col w-full">
            <span>{{ data.sheet_name }}</span>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <b>External Code</b>
          </div>
          <div class="vx-col w-full">
            <span>{{ data.external_code }}</span>
          </div>
        </div>

        <div class="vx-row mb-6 w-full">
          <div class="vx-col w-full">
            <b>Source</b>
          </div>
          <div class="vx-col w-full">
            <span>{{ sourceText(data.source) }}</span>
          </div>
        </div>
      </div>
      <!-- end left -->

      <!-- start left 2 -->
      <div class="vx-col w-3/12">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <b>Currency</b>
          </div>
          <div class="vx-col w-full">
            <span>{{ data.currency }}</span>
          </div>
        </div>

        <div class="vx-row mb-6 w-full">
          <div class="vx-col w-full">
            <b>Type</b>
          </div>
          <div class="vx-col w-full">
            <span>{{ typeText(data.type) }}</span>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <b>Valid From</b>
          </div>
          <div class="vx-col w-full">
            <span>{{ data.valid_from }}</span>
          </div>
        </div>
      </div>
      <!-- end left 2 -->

      <!-- start Right -->
      <div class="vx-col w-3/12">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <b>Percentage Referance</b>
          </div>
          <div class="vx-col w-full">
            <span>{{ data.percentage_reference }}</span>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <b>Budget Value</b>
          </div>
          <div class="vx-col w-full">
            <span>{{ data.budget_value }}</span>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <b>Valid To</b>
          </div>
          <div class="vx-col w-full">
            <span>{{ data.valid_to }}</span>
          </div>
        </div>
      </div>
      <!-- end right -->

      <!-- start Right 2-->
      <div class="vx-col w-3/12">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <b>Reference Code</b>
          </div>
          <div class="vx-col w-full">
            <span>{{ ref_code }}</span>
          </div>
        </div>

        <div class="vx-row mb-6 w-full">
          <div class="vx-col w-full">
            <b>Description</b>
          </div>
          <div class="vx-col w-full">
            <span>{{ data.description }}</span>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <b>Note</b>
          </div>
          <div class="vx-col w-full">
            <span>{{ data.note }}</span>
          </div>
        </div>

      </div>
      <!-- end right 2-->
    </div>
    <!-- end row -->

    <!-- main page -->
    <div class="vx-row mb-12">
      <div class="vx-col sm:w-1/1 w-full mb-base">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{
                    table.dataEnd == 0 ? 0 : table.limitShow * (table.isActive - 1) + 1
                  }}
                  - {{ table.limitShow * (table.isActive - 1) + table.dataEnd }} of
                  {{ table.recordsTotal }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  v-bind:key="item"
                  @click="changeLimit(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <div class="mx-auto"></div>
          <div class="vx-col sm:w-1/3 w-full">
            <div class="flex flex-wrap items-center justify-between">
              <vs-input
                class="mb-4 md:mb-0 mr-4 w-full"
                v-model="search"
                placeholder="Search by : "
              />
            </div>
          </div>
        </div>

        <vs-table :data="table.data" stripe border>
          <template slot="thead">
            <vs-th>No</vs-th>
            <!-- <vs-th>Reference Table</vs-th> -->
            <vs-th>Supplier</vs-th>
            <vs-th>Cost Center</vs-th>
            <vs-th>Territory</vs-th>
            <vs-th>Precentage</vs-th>
            <vs-th>Value Distribution</vs-th>
            <vs-th>Status</vs-th>
            <vs-th>Remark</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="idx" v-for="(dt, idx) in data">
              <vs-td style="width: 5%">{{
                table.limitShow * (table.isActive - 1) + 1 + idx
              }}</vs-td>
              <!-- <td>{{ dt.ReferenceTable }}</td> -->
              <td>{{ dt.Data.supplier_code }} - {{ dt.Data.supplier_name }}</td>
              <td>{{ dt.Data.cost_center_code }} - {{ dt.Data.cost_center_name }}</td>
              <vs-td> {{ dt.Data.territory_code }} - {{ dt.Data.territory_name }} </vs-td>
              <vs-td> {{ dt.Data.percentage }}% </vs-td>
              <vs-td> {{ dt.Data.value_distribution }} </vs-td>
              <td>
                <span :class="['badge badge-pill', 'badge-' + colorStatus(dt.Status)]">{{
                  dt.Status
                }}</span>
              </td>
              <td>{{ dt.Remark }}</td>
            </vs-tr>
          </template>
        </vs-table>

        <vs-pagination
          style="padding-top: 5px"
          :total="table.maxPage"
          v-model="currentPage"
          :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'"
          :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import detailSelectedBudget from "../component/detail_selected_so.vue";
// import moment from 'moment'

export default {
  components: {
    // detailSelectedBudget
  },
  props: ["data", "table","ref_code"],
  mounted() {},
  data() {
    return {
      dataImport: [],
      search: "",
      detail: false,
    };
  },
  watch: {},
  computed: {
    currentPage: {
      get() {
        return 1;
      },
      set(page) {
        this.page(page);
      },
    },
  },
  methods: {
    dateFormat(date) {
      if (date) {
        // return moment(String(date)).utcOffset(0).format('DD/MM/YYYY hh:mm')
        let dt = new Date(date);
        let d = dt.getDate();
        let m = dt.getMonth() + 1;
        let y = dt.getFullYear();
        let h = dt.getHours();
        let mm = dt.getMinutes();
        let s = dt.getSeconds();
        var formatNumber = function (num = 0) {
          if (num < 10) {
            return 0 + "" + num;
          }
          return num;
        };
        return (
          y +
          "-" +
          formatNumber(m) +
          "-" +
          formatNumber(d) +
          " " +
          formatNumber(h) +
          ":" +
          formatNumber(mm) +
          ":" +
          formatNumber(s)
        );
      }
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },
    page(page) {
      this.$emit("ChangePage", page);
      // this.getData(page);
      return page;
    },
    changeLimit(limit) {
      // this.limitShow = limit;
      this.$emit("ChangeLimit", limit, this.table.isActive);
      // this.getData(this.table.isActive);
    },
    closeImport() {
      this.$emit("close");
    },
    colorStatus(sts = "Failed") {
      let color = "danger";
      if (sts == "Pending") {
        color = "primary";
      } else if (sts == "Success" || sts == "Imported") {
        color = "success";
      } else if (sts == "Processing") {
        color = "warning";
      }
      return color;
    },
    sourceText(i = 0){
      let source =""
      if (i==1) {
        source = "Internal"
      }else if (i==2){
        source = "Supplier"
      }else if (i==3){
        source = "Mix"
      }
      return source
    },
    typeText(i = 0){
      let typeText =""
      if (i==1) {
        typeText = "Quantity"
      }else if (i==2){
        typeText = "Value"
      }
      return typeText
    }
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
.badge {
  font-weight: 400;
  color: #fff;
  background-color: #7367f0;
}
.badge-success {
  color: #fff;
  background-color: #28c76f;
}
.badge-danger {
  color: #fff;
  background-color: #da0000;
}
.badge-primary {
  color: #fff;
  background-color: #7367f0;
}
.badge-warning {
  color: #fff;
  background-color: #bac700;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
</style>
