<template>
  <div>
    <vs-button
      class="mb-12"
      color="danger"
      icon-pack="feather"
      @click="closeImport"
      icon="icon-arrow-left"
      >Back</vs-button
    >
    <!-- main page -->
    <div class="vx-row mb-12">
      <div class="vx-col sm:w-1/1 w-full mb-base">
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>File</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <input
              class="inputx"
              type="file"
              ref="file"
              name="file"
              accept=".xls, .xlsx"
            />
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-button class="ml-auto mt-2" @click="handleSubmit">Submit</vs-button>
            <vs-button class="ml-4 mt-2" type="border" color="warning" @click="handleDownloadTemplate"
              >Download Template</vs-button
            >
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{
                    table.dataEnd == 0 ? 0 : table.limitShow * (table.isActive - 1) + 1
                  }}
                  - {{ table.limitShow * (table.isActive - 1) + table.dataEnd }} of
                  {{ table.recordsTotal }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  v-bind:key="item"
                  @click="changeLimit(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <div class="mx-auto"></div>
          <div class="vx-col sm:w-1/3 w-full">
            <div class="flex flex-wrap items-center justify-between">
              <vs-input
                class="mb-4 md:mb-0 mr-4 w-full"
                v-model="search"
                placeholder="Search by : "
              />
            </div>
          </div>
        </div>

        <vs-table :data="table.data" stripe border>
          <template slot="thead">
            <vs-th>No</vs-th>
            <vs-th>Reference Code</vs-th>
            <vs-th>Company</vs-th>
            <vs-th>File</vs-th>
            <vs-th>Import date</vs-th>
            <vs-th>Imported by</vs-th>
            <vs-th>Status</vs-th>
            <vs-th>Remark</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="idx" v-for="(dt, idx) in data">
              <vs-td style="width: 5%">{{
                table.limitShow * (table.isActive - 1) + 1 + idx
              }}</vs-td>
              <td>{{ dt.ReferenceCode }}</td>
              <td>{{ dt.CompanyName }}</td>
              <td>
                <a href="#" @click="downloadFileAwsS3(dt.FilePath)">{{ dt.FileName }}</a>
              </td>
              <vs-td>
                {{ dateFormat(dt.CreatedAt) }}
              </vs-td>
              <td>{{ dt.UserName }}</td>
              <td>
                <span :class="['badge badge-pill', 'badge-' + colorStatus(dt.Status)]">{{
                  dt.Status
                }}</span>
                <br />
                Sheet Name : {{ dt.Data.sheet_name }} <br />
                Pending :{{ dt.Pending }} <br />
                Processing :{{ dt.Processing }} <br />
                Success :{{ dt.Imported }} <br />
                Failed :{{ dt.Failed }}<br />
              </td>
              <td>{{ dt.Remark }}</td>
              <vs-td style="text-align: left">
                <feather-icon
                  title="Show"
                  icon="EyeIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                  @click="handleDetail(dt)"
                />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>

        <vs-pagination
          style="padding-top: 5px"
          :total="table.maxPage"
          v-model="currentPage"
          :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'"
          :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"
        />
      </div>
    </div>
    <vs-popup fullscreen title="Log Details" :active.sync="detail">
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <DatatableImportDetail
            :data="dataDetail.Data"
            :ref_code="dataDetail.ReferenceCode"
            :table="tableLine"
            @ChangePage="changePageLine"
            @ChangeLimit="changeLimitLine"
          ></DatatableImportDetail>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import DatatableImportDetail from "./datatable-import-detail";
import moment from "moment";
// import detailSelectedBudget from "../component/detail_selected_so.vue";
// import moment from 'moment'

export default {
  components: {
    DatatableImportDetail,
    // detailSelectedBudget
  },
  props: ["uploadTemplate"],
  mounted() {},
  data() {
    return {
      dataImport: [],
      table: {
        limits: [10, 25, 50, 100, "All"],
        limitShow: 10,
        maxPage: 2,
        search: "",
        recordsTotal: 0,
        dataEnd: 0,
        data: [],
        isActive: 1,
      },
      tableLine: {
        limits: [10, 25, 50, 100, "All"],
        limitShow: 10,
        maxPage: 2,
        search: "",
        recordsTotal: 0,
        dataEnd: 0,
        data: [],
        isActive: 1,
      },
      dataDetail: {
        Data:{
          sheet_name :"",
        }
      },
      search: "",
      detail: false,
    };
  },
  watch: {},
  computed: {
    currentPage: {
      get() {
        return 1;
      },
      set(page) {
        this.page(page);
      },
    },
  },
  methods: {
    dateFormat(date) {
      if (date) {
        // return moment(String(date)).utcOffset(0).format('DD/MM/YYYY hh:mm')
        let dt = new Date(date);
        let d = dt.getDate();
        let m = dt.getMonth() + 1;
        let y = dt.getFullYear();
        let h = dt.getHours();
        let mm = dt.getMinutes();
        let s = dt.getSeconds();
        var formatNumber = function (num = 0) {
          if (num < 10) {
            return 0 + "" + num;
          }
          return num;
        };
        return (
          y +
          "-" +
          formatNumber(m) +
          "-" +
          formatNumber(d) +
          " " +
          formatNumber(h) +
          ":" +
          formatNumber(mm) +
          ":" +
          formatNumber(s)
        );
      }
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },
    page(page) {
      this.getData(page);
      return page;
    },
    changeLimit(limit) {
      this.table.limitShow = limit;
      this.getData(this.table.isActive);
    },
    changePageLine(page) {
      this.getData(page);
      return page;
    },
    changeLimitLine(limit, is_active) {
      this.tableLine.limitShow = limit;
      this.getDataLine(this.tableLine.isActive);
    },
    closeImport() {
      this.$emit("close");
    },
    getData(page = 1) {
      this.table.isActive = page;
      this.$vs.loading();

      var parameter = {
        limit: this.table.limitShow,
        offset: this.table.limitShow * (page - 1),
      };

      this.$http
        .get("/api/v1/promotion-budget/log-import", {
          params: parameter,
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.drawData = this.drawData + 1;
            this.table.data = resp.data.Data;
            this.table.recordsTotal = resp.data.RecordsTotal;
            // console.log(this.recordsTotal,'/',this.table.limitShow)
            this.table.maxPage = Math.ceil(
              this.table.recordsTotal / this.table.limitShow
            );
            this.table.dataEnd = resp.data.ResultsTotal;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        });
    },
    getDataLine(page = 1) {
      this.tableLine.isActive = page;
      this.$vs.loading();

      var parameter = {
        limit: this.tableLine.limitShow,
        offset: this.tableLine.limitShow * (page - 1),
      };

      this.$http
        .get("/api/v1/promotion-budget/log-import/" + this.dataDetail.ID, {
          params: parameter,
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.drawData = this.drawData + 1;
            this.tableLine.data = resp.data.Data;
            this.tableLine.recordsTotal = resp.data.RecordsTotal;
            // console.log(this.recordsTotal,'/',this.tableLine.limitShow)
            this.tableLine.maxPage = Math.ceil(
              this.tableLine.recordsTotal / this.tableLine.limitShow
            );
            this.tableLine.dataEnd = resp.data.ResultsTotal;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        });
    },
    handleDetail(data) {
      this.detail = true;
      this.dataDetail = data;
      this.getDataLine(this.tableLine.isActive);
    },

    colorStatus(sts = "Failed") {
      let color = "danger";
      if (sts == "Pending") {
        color = "primary";
      } else if (sts == "Success" || sts == "Imported") {
        color = "success";
      } else if (sts == "Processing") {
        color = "warning";
      }
      return color;
    },
    handleDownloadTemplate() {
      this.$vs.loading();
      var fileTitle = "PROMOTION_BUDGET_IMPORT_TEMPLATE_"+moment().format("YYYY-MM-DD");
      this.$http
        .get("/api/v1/promotion-budget/download-template", {
          responseType: "arraybuffer",
          headers: {
            Accept: "application/octet-stream",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          console.log(resp);
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;

            fileLink.setAttribute(
              "download",
              fileTitle.toUpperCase() + ".xlsx"
            );
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
    },
    downloadTemplate(){
      this.$http
          .get("/api/v1/promotion-budget/download-template", this.formData)
          .then((resp) =>{
            if (resp.code== 200) {
              this.downloadFileAwsS3(resp.data.path)
            }else{
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            console.log(error)
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: error,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          });
    },
    handleSubmit() {
      var file = this.$refs.file.files[0];
      console.log(this.$refs.file.files);
      // var bo = true;
      // if (bo) {
      if (file == null) {
        this.$vs.notify({
          color: "danger",
          title: "Form Validation",
          text: "File is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } else {
        var dateNow = new Date();
        var now =
          dateNow.getFullYear() + "" + (dateNow.getMonth() + 1) + "" + dateNow.getDate();
        now +=
          +"" +
          dateNow.getHours() +
          "" +
          dateNow.getMinutes() +
          "" +
          dateNow.getSeconds();
        this.formData = new FormData();
        this.formData.append("file", file);
        this.formData.append("user_agent", navigator.userAgent);
        this.formData.append("file_name", now + "_" + file.name);
        this.formData.append("url", "/api/v1/promotion-budget/import");
        this.$vs.loading();
        let endpoint ="/api/v1/promotion-budget"
        if (this.uploadTemplate) {
          endpoint += "/upload-template"
        }else{
          endpoint += "/import"
        }
        this.$http
          .post(endpoint, this.formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.status == "success") {
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
              this.uploadReady = false;
              this.$nextTick(() => {
                this.uploadReady = true;
              });
              this.getData();
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            console.log(error)
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: error,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          });
      }
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
.badge {
  font-weight: 400;
  color: #fff;
  background-color: #7367f0;
}
.badge-success {
  color: #fff;
  background-color: #28c76f;
}
.badge-danger {
  color: #fff;
  background-color: #da0000;
}
.badge-primary {
  color: #fff;
  background-color: #7367f0;
}
.badge-warning {
  color: #fff;
  background-color: #bac700;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
</style>
