<template>
  <div class="vx-col md:w-1/1 w-full">
    <vx-card title="Promotion Budget">
      <!--
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Sales Office</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                        class="selectExample"
                        v-model="territory"
                        :options="optionTerritory"
                        :multiple="false"
                        :allow-empty="false"
                        :group-select="false"
                        :max-height="120"
                        :limit="3"
                        placeholder="Type to search"
                        track-by="code"
                        label="Name"
                    >

                        <template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                                <span class="option__title">({{ props.option.code }}) {{ props.option.name }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">({{ props.option.code }}) {{ props.option.name }}</span>
                            </div>
                        </template>

                    </multiselect>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Sales Channel</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                        class="selectExample"
                        v-model="salesChannel"
                        :options="optionSalesChannel"
                        :multiple="false"
                        :allow-empty="false"
                        :group-select="false"
                        :max-height="120"
                        :limit="3"
                        placeholder="Type to search"
                        track-by="code"
                        label="name"
                    >

                        <template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                                <span class="option__title">({{ props.option.code }}) {{ props.option.name }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">({{ props.option.code }}) {{ props.option.name }}</span>
                            </div>
                        </template>

                    </multiselect>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Distribution Channel</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                        class="selectExample"
                        v-model="customerCategory"
                        :options="optionCustomerCategory"
                        :multiple="false"
                        :allow-empty="false"
                        :group-select="false"
                        :max-height="120"
                        :limit="3"
                        placeholder="Type to search"
                        track-by="ID"
                        label="name"
                    >

                        <template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                                <span class="option__title">{{ props.option.name }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">{{ props.option.name }}</span>
                            </div>
                        </template>

                    </multiselect>
                </div>
            </div>
            <hr>
            -->
      <vs-tabs :color="colorx" ref="tabs">
        <vs-tab @click="changeTab(0)" label="Form">
          <div class="con-tab-ejemplo">
            <br />
            <!-- <formBudget :territoryCode="territory.code" :territoryId="territory.id" :salesChannel="salesChannel.code" :customerCategory="customerCategory.ID" :selectedBudget="selectedBudget" :optionTerritory="optionTerritory" v-on:selectBudget="updateSelectedBudget"></formBudget> -->
            <formBudget
              :selectedBudget="selectedBudget"
              :disabledFrom="disabledFrom"
              :sourceTab="sourceTab"
              v-on:selectBudget="updateSelectedBudget"
              v-on:backTo="backToSourceTab"
            ></formBudget>
          </div>
        </vs-tab>
        <vs-tab @click="changeTab(1)" label="Tracker">
          <div class="con-tab-ejemplo">
            <br />
            <!-- <monitoring :territory="territory" :salesChannel="salesChannel" :customerCategory="customerCategory"  v-on:selectBudget="updateSelectedBudget" v-on:redirectTab="redirectTab"></monitoring> -->
            <monitoring
              v-on:selectBudget="updateSelectedBudget"
              v-on:redirectTab="redirectTab"
              :sourceTab="sourceTab"
            ></monitoring>
          </div>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>

<script>
import formBudget from "./form/index.vue";
import monitoring from "./monitoring/index.vue";

export default {
  components: {
    formBudget,
    monitoring,
  },
  mounted() {
    // this.getOptionTerritory();
    // this.getOptionSalesChannel();
    // this.getOptionCustomerCategory();
    // console.log("mounted", this.selectedBudget);
  },
  data: () => ({
    tabColor: ["primary", "rgb(62, 201, 214)"],
    tabIndex: 0,
    disabledFrom: false,
    sourceTab: 0,
    territory: {},
    optionTerritory: [{}],
    salesChannel: {},
    optionSalesChannel: [{}],
    customerCategory: {},
    optionCustomerCategory: [{}],
    colorx: "success",
    selectedBudget: {},
  }),
  watch: {
    // territory: function(val) {
    //     console.log(val.id);
    //     console.log(val.code);
    // }
  },
  methods: {
    changeTab(index) {
      // console.log("before", this.tabIndex);
      // console.log("after", index);
      this.tabIndex = index;
      this.colorx = this.tabColor[index];
    },
    getOptionTerritory() {
      this.$http.get("/api/v1/master/territory").then((resp) => {
        this.optionTerritory = resp.data.records;
        this.territory = resp.data.records[0];
      });
    },
    getOptionSalesChannel() {
      this.$http.get("/api/v1/sales-channel/personal").then((resp) => {
        // console.log(resp)
        if (resp.code == 200) {
          this.optionSalesChannel = [];
          this.optionSalesChannel.push({ code: "All" });
          for (var salesChannel = 0; salesChannel < resp.data.length; salesChannel++) {
            this.optionSalesChannel.push(resp.data[salesChannel]);
          }
          this.salesChannel = this.optionSalesChannel[0];
          // this.territory = resp.data.territory[0];
        } else {
          // this.$vs.loading.close();
          this.$router.push("/");
        }
      });
    },
    getOptionCustomerCategory() {
      this.$http.get("/api/v1/master/customer-category-all").then((resp) => {
        if (resp.code == 200) {
          this.optionCustomerCategory = [];
          this.optionCustomerCategory.push({ name: "All" });
          for (
            var customerCategory = 0;
            customerCategory < resp.data.length;
            customerCategory++
          ) {
            this.optionCustomerCategory.push(resp.data[customerCategory]);
          }
          this.customerCategory = this.optionCustomerCategory[0];
        } else {
          // this.$vs.loading.close();
          this.$router.push("/");
        }
      });
    },
    updateSelectedBudget(budget) {
      this.selectedBudget = budget;
      this.changeTab(0);
      // console.log("selected", this.selectedBudget);
    },
    backToSourceTab() {
      console.log("this.sourceTab", this.sourceTab);
      this.$refs.tabs.activeChild(1);
    },
    redirectTab(index, disabled, tab) {
      this.disabledFrom = disabled;
      this.sourceTab = tab;
      this.$refs.tabs.activeChild(index);
    },
  },
};
</script>

<style scoped>
.vs-collapse-item--content {
  height: 100%;
  max-height: 14444px;
}
</style>
